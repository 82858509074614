<template>
  <div class="search-by-date">
    <section v-if="stage === 1" class="month-selection">
      <h1>
        Search By Date
      </h1>

      <p>
        Please select month below
      </p>
    
      <div class="months">
        <button v-for="month in months" :key="month.key" type="button" class="month-button" @click="selectMonth(month)">
          {{ month.name }}
        </button>
      </div>
    </section>

    <section v-if="stage === 2" class="day-selection">
      <div class="day-selection-header">
        <h2>{{ selectedMonth.name }}</h2>
        <button type="button" class="change-month-button" @click="backToChangeMonth">{{ changeMonthText }}</button>

        <!-- <button style="visibility:hidden;">{{ changeMonthText }}</button> -->
      </div>

      <div class="days">
        <div class="day-row" v-for="row in Math.ceil(selectedMonth.days / daysPerRow)" :key="row">
          <template v-for="day in daysPerRow">
            <button v-if="dayFromRow(row, day) <= selectedMonth.days" :key="day" type="button" class="day-button" @click="selectDay(dayFromRow(row, day))">
              {{ dayFromRow(row, day) }}
            </button>
            <button :key="day + 'hidden'" v-else class="day-button" style="visibility:hidden;">&nbsp;</button>
          </template>
        </div>
      </div>
    </section>

    <div :class="{ 'active': loading }" class="loading-wrap">
      <p class="loading-text">
        Loading, please wait...
      </p>
    </div>

    <p v-if="!loading && hasErrored">
      Sorry, we are unable to load the book for the date. Please try again later.
    </p>
    
    <pages
        v-if="pageImages.length > 0"
        :month="selectedMonthNumber"
        :day="selectedDay"
        :page-images="pageImages"
        ref="pages" />
  </div>
</template>

<script>
import months from '@/data/months'
import CrematoriumDetailsMixin from '@/mixins/CrematoriumDetailsMixin'

export default {
  name: 'SearchByDate',
  inject: ['api'],
  mixins: [ CrematoriumDetailsMixin ],
  data() {
    return {
      loading: false,
      hasErrored: false,
      changeMonthText: 'Change',
      months,
      daysPerRow: 7,
      stage: 1, // 1 = select month, 2 = select day
      selectedMonth: null,
      selectedDay: null,
      pageCount: null,
    }
  },
  mounted() {
    if (!this.allowSearchByDate) {
      this.$router.replace({ name: 'Home', params: { crematorium: this.$route.params.crematorium } })
    }
  },
  methods: {
    dayFromRow(row, day) {
      return ((row - 1) * this.daysPerRow) + day
    },
    
    selectMonth(month) {
      this.selectedMonth = month
      this.stage = 2
    },
    
    selectDay(day) {
      this.selectedDay = day
      this.$router.push({ name: 'Book', params: { crematorium: this.$route.params.crematorium }, query: { crematorium: this.$route.query.crematorium, day, month: this.selectedMonth.key }})
    },

    backToChangeMonth() {
      this.pageCount = 0
      this.selectedMonth = null
      this.selectedDay = null
      this.stage = 1
    },
  },
  computed: {
    selectedMonthNumber() {
      return this.selectedMonth ? this.selectedMonth.number : null
    },
    pageImages() {
      const pageImages = []

      for (let i = 1; i <= this.pageCount; i++) {
          const url = this.api.getPageUrl(this.$route.query.crematorium || this.$route.params.crematorium, this.selectedMonthNumber, this.selectedDay, i)
          pageImages.push(url)
      }

      return pageImages
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_button';

  .loading-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition-delay: .25s;
    transition-duration: .5s;
    transition-property: opacity;

    &:not(.active) {
      visibility: hidden;
      opacity: 0;
    }

    .loading-text {
      display: inline-block;
      background-color: white;
      padding: 2rem;
      text-align: center;
    }
  }

  .months {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .month-button {
    margin: 8px;
    min-width: 175px;
  }

  .day-button {
    margin: 8px;
    margin-top: 3px;
    min-width: 60px;
  }

  .day-selection-header {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .change-month-button {
    @include master-button-bg(darkred);
    margin-left: 20px;
  }

  .day-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>