<template>
    <table>
        <thead>
            <tr>
                <th>Last Name</th>
                <th>First Name(s)</th>
                <th>Date of Entry</th>
                <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody>          
            <tr v-for="entry in results" :key="entry.entryId" @click="$emit('view-entry', entry)">
                <td>
                    {{ entry.lastName }}
                </td>
                <td>
                    {{ entry.firstName }}
                </td>
                <td>
                    {{ entry.dayOfMonth + ' ' + entry.monthName }}
                </td>
                <td>
                    <router-link class="button view-button" :to="{ name: 'Entry', params: { crematorium: $route.params.crematorium }, query: { crematorium: $route.query.crematorium, entryId: entry.entryId }}">
                        View
                    </router-link>
                </td>
            </tr>
        </tbody>
        <tfoot>
        </tfoot>
    </table>
</template>

<style lang="scss" scoped>
    @import '@/scss/_button';

    table {
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        color: black;
        border-collapse: collapse;
        border: 0.8px solid black;
        border-radius: 8px;
    }

    th {
        @include master-button-bg($button-bg-colour);
        color: white;
        padding: 8px 16px;
        letter-spacing: 1px;
    }

    tbody > tr {
        cursor: pointer;

        &:hover {
            background-color: wheat;
            transition: background-color .5s;
        }
    }

    td {
        padding: 8px 32px;
        letter-spacing: 1px;
    }

    .view-button {
        margin: 0;
    }
</style>

<script>
export default {
    name: 'search-results',
    props: {
        results: { type: Array, required: true },
    },
}
</script>
