<template>
  <div>
    <h1>
      Start Your Search
    </h1>

    <template v-if="showCrematoriumChooser">
      <p>Please select the crematorium you wish to view</p>

      <div class="child-crem-container">
        <router-link v-for="(child, index) in childCrematoriums" :key="index" :to="{ name: 'Home', params: { crematorium: $route.params.crematorium }, query: { crematorium: child.hostName } }" class="child-crem-panel">
          <div>Book of Remembrance for</div>
          <div class="child-crem-name">{{ child.name }}</div>
        </router-link>
      </div>
    </template>
    <template v-else>
      <p>
        Here you can search for loved ones listed in our Books of Remembrance. Please select a search method below.
      </p>
      
      <section class="search-panel-container">
        <router-link v-for="(link, index) in links" :key="index" :to="{ name: link.name, params: { crematorium: $route.params.crematorium }, query: { crematorium: $route.query.crematorium } }" class="search-panel">
          <div class="icon-wrap">
            <img :src="'icons/' + link.icon" :alt="link.buttonText" />
          </div>

          <p v-html="link.text"></p>

          <button type="button" class="button">
            {{ link.buttonText }}
          </button>
        </router-link>
      </section>
    </template>

    <section class="staff-access">
      <router-link v-if="showStaffAccess" :to="{ name: 'Staff', params: { crematorium: $route.params.crematorium }, query: { crematorium: $route.query.crematorium } }">
        Staff Access
      </router-link>

      <button v-if="isStaff" type="button" @click="staffLogout">
        Staff Logout
      </button>
    </section>
  </div>
</template>

<script>
import store from '@/infrastructure/store'
import CrematoriumDetailsMixin from '@/mixins/CrematoriumDetailsMixin'
import BodyInputMixin from '@/mixins/BodyInputMixin'
import { PIN_CHARACTERS } from '@/data/characters'

const PIN_CHARACTER_TIMEOUT_MS = 5000

export default {
  name: 'Home',
  mixins: [ CrematoriumDetailsMixin, BodyInputMixin ],
  data() {
    return {
      sharedState: store.state,
      allowedKeys: PIN_CHARACTERS,
      clearTextTimerHandle: null,
    }
  },
  methods: {
    onSubmit() {
      this.$router.push({ name: 'Entry', params: { crematorium: this.$route.params.crematorium }, query: { crematorium: this.$route.query.crematorium, pin: this.text }})
    },
    onTextChange() {
      if (this.clearTextTimerHandle) {
        clearTimeout(this.clearTextTimerHandle)
        this.clearTextTimerHandle = null
      }

      this.clearTextTimerHandle = setTimeout(() => this.text = '', PIN_CHARACTER_TIMEOUT_MS)
    },
  },
  computed: {
    showCrematoriumChooser() {
      return this.childCrematoriums && this.childCrematoriums.length > 0 && !this.$route.query.crematorium
    },
    links() {
      const links = []

      if (this.allowSearchByDate) {
        links.push({
          name: 'SearchByDate',
          icon: 'date-icon.png',
          text: '<strong>Search by Date</strong> to view pages by date',
          buttonText: 'By Date',
        })
      }

      if (this.allowSearchByName) {
        links.push({
          name: 'SearchByName',
          icon: 'book-icon.png',
          text: '<strong>Search by Name</strong> to view pages by name',
          buttonText: 'By Name',
        })
      }

      if (this.allowSearchByPin) {
        links.push({
          name: 'SearchByPIN',
          icon: 'pin-card-icon.png',
          text: '<strong>Search by PIN</strong> or by using the number on your <strong>swipecard</strong>',
          buttonText: 'By PIN',
        })
      }

      return links
    }
  },
}
</script>

<style scoped lang="scss">
  @import '@/scss/_screen';
  @import '@/scss/_button';
  @import '@/scss/_mixins';

  .search-panel-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;

    @include desktop {
      flex-direction: row;
    }
  }

  .search-panel {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin: 1rem;
    color: black;
    text-decoration: none;
  

    @include desktop {
      margin: 1.8rem;
      max-width: 24rem;

      @include ie-only {
        // max-width not working properly on IE so unset this
        max-width: none;
      }
    }

    p {
      flex-grow: 1;
    }

    .button {
      margin: 0;
    }
  }

  .staff-access {
    text-align: left;
    margin: 0.75rem;

    a {
      color: blue;

      &:visited {
        color: blue;
      }
    }
  }

  .child-crem-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;

    @include desktop {
      flex-direction: row;
    }
  }

  .child-crem-panel {
    text-decoration: none;
    background: #D9FFFF;
    padding: 1rem;
    border: 2px solid #666;
    color: black;

    @include desktop {
      margin: 1.8rem;
      max-width: 24rem;

      @include ie-only {
        // max-width not working properly on IE so unset this
        max-width: none;
      }
    }
  }

  .child-crem-name {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 0.8rem;
  }
</style>
